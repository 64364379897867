import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import { ExternalHyperlink } from '@workday/canvas-kit-react/button';
import { InformationHighlight } from '@workday/canvas-kit-docs';
import { FontFamilyTokens } from '../../../src/components/content/tokens/TokenFontFamilyGrid';
import { FontSizeTokens } from '../../../src/components/content/tokens/TokenFontSizeGrid';
import { FontWeightTokens } from '../../../src/components/content/tokens/TokenFontWeightGrid';
import { TypeLevelTokens } from '../../../src/components/content/tokens/TokenTypeLevelGrid';
import { getInternalExternalURL } from '../../../src/utils/url';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PackageInfo = makeShortcode("PackageInfo");
const TokenTypeLevelGrid = makeShortcode("TokenTypeLevelGrid");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <InformationHighlight mdxType="InformationHighlight">
  <InformationHighlight.Icon style={{
        width: '24px'
      }} />
  <InformationHighlight.Heading>Looking for our old type tokens?</InformationHighlight.Heading>
  <InformationHighlight.Body>
    While we still support our old tokens, we’re migrating our documentation and usage guidance to
    reflect our new Tokens Web. It is <strong>not</strong> currently a requirement to update all
    your tokens to use v11, but we <strong>strongly</strong> encourage you to begin migrating as you
    can. If you're an engineer looking to migrate to our new tokens, we wrote a handy{' '}
    <ExternalHyperlink href={'https://github.com/Workday/canvas-tokens/discussions/77'} mdxType="ExternalHyperlink">
      migration guide
    </ExternalHyperlink>
    for you.
  </InformationHighlight.Body>
  <InformationHighlight.Link href={getInternalExternalURL('/v10/tokens/type')}>
    View v10 Type Token Docs.
  </InformationHighlight.Link>
    </InformationHighlight>
    <PackageInfo storybook="https://workday.github.io/canvas-tokens/?path=/docs/docs-system-tokens-type--docs" figma="https://www.figma.com/design/GmIqhQ7KqX6WQZFimdn756/Canvas-Tokens-v2?node-id=21683-7684&t=zaRL8ALlvmcTsWQc-4" package="@workday/canvas-tokens-web" mdxType="PackageInfo" />
    <h2 {...{
      "id": "usage-guidance"
    }}>{`Usage Guidance`}</h2>
    <p>{`The Canvas Type System contains a range of contrasting sizes built upon the foundational principles
of typographic design. The system is responsive, built using rem values with the base font size set
to `}<inlineCode parentName="p">{`16px`}</inlineCode>{` (`}<inlineCode parentName="p">{`1rem`}</inlineCode>{`), aligning to the base value for `}<a parentName="p" {...{
        "href": "/styles/tokens/space"
      }}>{`Canvas Spacing`}</a>{`. It’s flexible,
and configurable, purpose built for Workdays diverse and growing product suite. In this section you
will find the rationale behind the Canvas type system, and what is available to use.`}</p>
    <h2 {...{
      "id": "typeface"
    }}>{`Typeface`}</h2>
    <p>{`The Canvas Type system uses Roboto as its primary typeface, a typeface is a collection of fonts such
as ‘Roboto Bold’ and ‘Roboto Bold Italic’. Canvas utilizes Roboto's monospace counterpart, Roboto
Mono for specific use cases like tabular figures.`}</p>
    <p>{`To use the Canvas Kit font on web
`}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-kit/tree/master/modules/react-fonts"
      }}>{`install and import the `}<inlineCode parentName="a">{`@workday/canvas-kit-react-fonts`}</inlineCode>{` module`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` This module sources fonts from the Workday CDN.`}</p>
    </blockquote>
    <h3 {...{
      "id": "font-stack"
    }}>{`Font Stack`}</h3>
    <p>{`The font stack contains a number of similar typefaces to support a series of fallbacks for
circumstances when Roboto cannot be loaded on a user's device.`}</p>
    <p><strong parentName="p">{`Sans-serif font stack`}</strong></p>
    <code>'Roboto', 'Helvetica Neue', 'Helvetica', Arial, sans-serif</code>
    <p><strong parentName="p">{`Mono font stack`}</strong></p>
    <code>'Roboto Mono', 'Courier New', 'Courier', monospace</code>
    <h2 {...{
      "id": "type-styles"
    }}>{`Type Styles`}</h2>
    <p>{`The Canvas type system contains a total of 12 type styles to create robust typographic hierarchies.
They are informed by the Canvas Type Scale to build a predictable and rational system. Styles are
categorized by levels and sizes, there are 4 type levels, each level is coupled with 3 sizes. Each
size applies `}<inlineCode parentName="p">{`fontFamily`}</inlineCode>{`, `}<inlineCode parentName="p">{`fontSize`}</inlineCode>{`, `}<inlineCode parentName="p">{`fontWeight`}</inlineCode>{`, `}<inlineCode parentName="p">{`lineHeight`}</inlineCode>{`, `}<inlineCode parentName="p">{`letterSpacing`}</inlineCode>{`, and `}<inlineCode parentName="p">{`color`}</inlineCode>{`
styles for you, so you can create consistent type quickly and easily. Type styles are highly
composable and allow for easy modifications to be made such as changes to weight, color, and the
addition of variants.`}</p>
    <TokenTypeLevelGrid mdxType="TokenTypeLevelGrid" />
    <h3 {...{
      "id": "web-example"
    }}>{`Web Example`}</h3>
    <h3 {...{
      "id": "javascript--typescript"
    }}>{`Javascript / Typescript`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`// styles.ts
import {system} from '@workday/canvas-tokens-web';
import {createStyles} from '@workday/canvas-kit-styling';

const headingStyles = createStyles({
  ...system.type.heading.small,
})

const bodyStyles = createStyles({
  minWidth: 48rem;
  ...system.type.body.small,
});
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` We are using `}<inlineCode parentName="p">{`createStyles`}</inlineCode>{` which wraps our tokens in `}<inlineCode parentName="p">{`var(--\${tokenName})`}</inlineCode>{` and allows
you to spread the token styles onto the element.`}</p>
    </blockquote>
    <h3 {...{
      "id": "html"
    }}>{`HTML`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`// index.html
<h2 className="cnvs-sys-type-heading-small">Heading</h2>
<p className="cnvs-sys-type-body-small">Body text</p>
`}</code></pre>
    <h2 {...{
      "id": "typescale"
    }}>{`Typescale`}</h2>
    <p>{`The Canvas type scale is divisible by 2, this provides a wide range of type sizes to choose from.
The divisible scale provides a logical order of predictable sizes. The scale is informed by Canvas
spacing principles to ensure consistent spacing is maintained in Workday's UI when other elements
are included alongside type.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6d7597f5f1c8fb692fb2d40ebe18503f/07a9c/type-scale.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.756756756756758%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Illustration demonstrating canvas type scale.",
            "title": "Illustration demonstrating canvas type scale.",
            "src": "/static/6d7597f5f1c8fb692fb2d40ebe18503f/50383/type-scale.png",
            "srcSet": ["/static/6d7597f5f1c8fb692fb2d40ebe18503f/1efb2/type-scale.png 370w", "/static/6d7597f5f1c8fb692fb2d40ebe18503f/50383/type-scale.png 740w", "/static/6d7597f5f1c8fb692fb2d40ebe18503f/07a9c/type-scale.png 1440w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Canvas Kit v5 introduced `}<inlineCode parentName="p">{`rem`}</inlineCode>{` units (instead of `}<inlineCode parentName="p">{`px`}</inlineCode>{`) to our type tokens. This update follows the
guidance `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#resize-text"
      }}>{`from the WCAG spec`}</a>{` and provides better support
for users who change their browser's default font size. If you'd like to learn more about `}<inlineCode parentName="p">{`rem`}</inlineCode>{` and
relative units, you can review this
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/Values_and_units#ems_and_rems"
      }}>{`documentation on CSS values and units`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` We are using `}<inlineCode parentName="p">{`16px`}</inlineCode>{` as our base font-size for these values. This is a browser standard
and also fairly common across Workday. However, `}<em parentName="p">{`if your body text is set to a value other than
`}<inlineCode parentName="em">{`16px`}</inlineCode></em>{`, you will need to adjust that value for text to render properly.`}</p>
    </blockquote>
    <h3 {...{
      "id": "baseline-grid"
    }}>{`Baseline Grid`}</h3>
    <p>{`A baseline grid is the foundation that type rests on. It is a series of rows that are used to create
consistent vertical spacing with text. The Canvas Baseline grid of 4px is also informed by Canvas
spacing principles, allowing uniform spacing to be maintained across Workday's UI.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/10be2a15151fd72ca576610f07454fa9/07a9c/type-baseline.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.837837837837835%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Illustration demonstrating canvas baseline grid.",
            "title": "Illustration demonstrating canvas baseline grid.",
            "src": "/static/10be2a15151fd72ca576610f07454fa9/50383/type-baseline.png",
            "srcSet": ["/static/10be2a15151fd72ca576610f07454fa9/1efb2/type-baseline.png 370w", "/static/10be2a15151fd72ca576610f07454fa9/50383/type-baseline.png 740w", "/static/10be2a15151fd72ca576610f07454fa9/07a9c/type-baseline.png 1440w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "line-height"
    }}>{`Line Height`}</h2>
    <p>{`Line height controls the amount of space between lines of text, line height is used to scale text
proportionally and is directly informed by the Canvas baseline grid to create uniform line heights
across type styles.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d023b0d4505ba5510583bae3c94326a7/07a9c/type-lineheight.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Illustration demonstrating canvas type line heights.",
            "title": "Illustration demonstrating canvas type line heights.",
            "src": "/static/d023b0d4505ba5510583bae3c94326a7/50383/type-lineheight.png",
            "srcSet": ["/static/d023b0d4505ba5510583bae3c94326a7/1efb2/type-lineheight.png 370w", "/static/d023b0d4505ba5510583bae3c94326a7/50383/type-lineheight.png 740w", "/static/d023b0d4505ba5510583bae3c94326a7/07a9c/type-lineheight.png 1440w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "font-weight"
    }}>{`Font Weight`}</h2>
    <p>{`Weight can add additional emphasis to typographic hierarchies, using weights can highlight the
importance of the content displayed on a device. Canvas recommends the use of Bold, Medium and
Regular weights to support the majority of use cases encountered. These can be used on any level of
the type hierarchy. Light weights are reserved for large sized text, the lower contrast of light
weights makes it difficult to read at small sizes.`}</p>
    <ul className="font-weights">
  <li style={{
        fontWeight: 300
      }}>Light - 300</li>
  <li style={{
        fontWeight: 400
      }}>Regular - 400</li>
  <li style={{
        fontWeight: 500
      }}>Medium - 500</li>
  <li style={{
        fontWeight: 700
      }}>Bold - 700</li>
    </ul>
    <h3 {...{
      "id": "web-example-1"
    }}>{`Web Example`}</h3>
    <h3 {...{
      "id": "javascript--typescript-1"
    }}>{`Javascript / Typescript`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`// styles.ts
import {system} from '@workday/canvas-tokens-web';

const styles = {
  fontWeight: \`var(\${system.fontWeight.bold})\`,
};
`}</code></pre>
    <h3 {...{
      "id": "css"
    }}>{`CSS`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`// styles.css
@import '@workday/canvas-tokens-web/css/system/_variables.css';
.bold {
  font-weight: var(--cnvs-sys-font-weight-bold);
}
`}</code></pre>
    <FontWeightTokens mdxType="FontWeightTokens" />
    <h2 {...{
      "id": "type-color"
    }}>{`Type Color`}</h2>
    <p>{`Using type colors applies comfortable contrast ratios to type and makes the content presented to the
reader much easier to consume. Using the type colors provided by Canvas will create texture in your
hierarchies, building visual interest as well as indicating the function of typographic elements.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2a3cea78ef7373c691ef314d7f897246/07a9c/type-color.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.67567567567567%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Illustration of color used for type.",
            "title": "Illustration of color used for type.",
            "src": "/static/2a3cea78ef7373c691ef314d7f897246/50383/type-color.png",
            "srcSet": ["/static/2a3cea78ef7373c691ef314d7f897246/1efb2/type-color.png 370w", "/static/2a3cea78ef7373c691ef314d7f897246/50383/type-color.png 740w", "/static/2a3cea78ef7373c691ef314d7f897246/07a9c/type-color.png 1440w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "variants"
    }}>{`Variants`}</h2>
    <p>{`Variants allow Canvas type styles to be modified beyond what is available by default, variants allow
you to build detail on top of your typographic hierarchies that can aid your readers' understanding
of the information displayed in your products.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3b7d2bf84cd63ef83f4dd7e58ac4aad9/07a9c/type-variant.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.13513513513514%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Illustration of type variants.",
            "title": "Illustration of type variants.",
            "src": "/static/3b7d2bf84cd63ef83f4dd7e58ac4aad9/50383/type-variant.png",
            "srcSet": ["/static/3b7d2bf84cd63ef83f4dd7e58ac4aad9/1efb2/type-variant.png 370w", "/static/3b7d2bf84cd63ef83f4dd7e58ac4aad9/50383/type-variant.png 740w", "/static/3b7d2bf84cd63ef83f4dd7e58ac4aad9/07a9c/type-variant.png 1440w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used for making errors more visible`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used for help text and secondary content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`inverse`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used for any text on a dark or colored background`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "web-example-2"
    }}>{`Web Example`}</h3>
    <h3 {...{
      "id": "javascript--typescript-2"
    }}>{`Javascript / Typescript`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import {system} from '@workday/canvas-tokens-web';

const errorColorStyles = {
  color: \`var(\${system.color.text.critical.default})\`,
};

const hintColorStyles = {
  color: \`var(\${system.color.text.hint})\`,
};

const inverseColorStyles = {
  color: \`var(\${system.color.text.inverse})\`,
};
`}</code></pre>
    <h2 {...{
      "id": "additional-considerations"
    }}>{`Additional Considerations`}</h2>
    <p>{`For additional guidance when using All Caps, Hyperinks, and choosing appropriate Line Lengths in
your products, please read the sections below.`}</p>
    <h3 {...{
      "id": "all-caps"
    }}>{`All Caps`}</h3>
    <p>{`All Caps, or Uppercase characters can be difficult to read when frequently used on a page. All Caps
can seem to shout at the reader when overused, but authoritative when used sparingly. A great
example of All caps are Canvas’s Status Indicators, these small text based indicators pack a punch,
while they are small, they stand out on a page in work to quickly inform the reader of an important
status.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a87d4e5774f87b26e2fb943776807f21/07a9c/type-all-caps.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.054054054054056%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Illustration demonstrating how to use all caps.",
            "title": "Illustration demonstrating how to use all caps.",
            "src": "/static/a87d4e5774f87b26e2fb943776807f21/50383/type-all-caps.png",
            "srcSet": ["/static/a87d4e5774f87b26e2fb943776807f21/1efb2/type-all-caps.png 370w", "/static/a87d4e5774f87b26e2fb943776807f21/50383/type-all-caps.png 740w", "/static/a87d4e5774f87b26e2fb943776807f21/07a9c/type-all-caps.png 1440w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "hyperlinks"
    }}>{`Hyperlinks`}</h3>
    <p>{`Hyperlinks are used to create shortcuts to other locations using the link variant, these should link
to related information for the user to read if they wish. Hyperlink styles should be used sparingly
as the high contrast style can become a burden to the reader if poorly implemented. Never embed a
hyperlink into an entire paragraph of text, instead, embed a key word that relates to the
information you wish to link off to.`}</p>
    <SideBySide mdxType="SideBySide">
      <Suggestion guidance="Embed a hyperlink into a key word." mdxType="Suggestion">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "302px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/15d89efc9eb9cf6ea6223f52f63e5db0/5f1d2/type-do.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "24.834437086092716%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image demonstrating hyperlinking key words.",
                "title": "Image demonstrating hyperlinking key words.",
                "src": "/static/15d89efc9eb9cf6ea6223f52f63e5db0/5f1d2/type-do.png",
                "srcSet": ["/static/15d89efc9eb9cf6ea6223f52f63e5db0/5f1d2/type-do.png 302w"],
                "sizes": "(max-width: 302px) 100vw, 302px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Suggestion>
      <Suggestion status="error" guidance="Embed a hyperlink into an entire paragraph." mdxType="Suggestion">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "302px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/13e5d438b1b483ceca8bf404bde25bcd/5f1d2/type-dont.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "24.834437086092716%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image demonstrating hyperlinking an entire paragraph.",
                "title": "Image demonstrating hyperlinking an entire paragraph.",
                "src": "/static/13e5d438b1b483ceca8bf404bde25bcd/5f1d2/type-dont.png",
                "srcSet": ["/static/13e5d438b1b483ceca8bf404bde25bcd/5f1d2/type-dont.png 302w"],
                "sizes": "(max-width: 302px) 100vw, 302px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Suggestion>
    </SideBySide>
    <h3 {...{
      "id": "line-length"
    }}>{`Line Length`}</h3>
    <p>{`Line length can determine the readability of paragraphs, the recommended number of characters in a
line of text should range from 45-75 characters per line. Canvas recommends 64 characters per line
to produce comfortable paragraphs for optimal readability.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/55c97b42b00a27487d343adb4186a5b9/07a9c/type-line-lenght.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.75675675675676%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Illustration demonstrating comfortable line lengths for reading.",
            "title": "Illustration demonstrating comfortable line lengths for reading.",
            "src": "/static/55c97b42b00a27487d343adb4186a5b9/50383/type-line-lenght.png",
            "srcSet": ["/static/55c97b42b00a27487d343adb4186a5b9/1efb2/type-line-lenght.png 370w", "/static/55c97b42b00a27487d343adb4186a5b9/50383/type-line-lenght.png 740w", "/static/55c97b42b00a27487d343adb4186a5b9/07a9c/type-line-lenght.png 1440w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "web-examples"
    }}>{`Web Examples`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`type`}</inlineCode>{` tokens are divided into three main parts:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`levels`}</inlineCode>{` (the type hierarchy)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`properties`}</inlineCode>{` (`}<inlineCode parentName="li">{`fontFamilies`}</inlineCode>{`, `}<inlineCode parentName="li">{`fontSizes`}</inlineCode>{`, and `}<inlineCode parentName="li">{`fontWeights`}</inlineCode>{`)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`variants`}</inlineCode>{` (modifiers for type styles)`}</li>
    </ul>
    <p>{`Each part serves its own purpose but is also designed to be combined with the others. Knowing how
and when to combine tokens is key to getting the most out of them.`}</p>
    <h3 {...{
      "id": "properties"
    }}>{`Properties`}</h3>
    <p>{`As previously mentioned, you'll most often you will want to reach for `}<inlineCode parentName="p">{`levels`}</inlineCode>{`, but in some
situations you may only need to set one or two type values for styling. Type `}<inlineCode parentName="p">{`properties`}</inlineCode>{` give you
an atomic-level of control when you want to explicitly set a particular value.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fontFamilies`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains font-family tokens: `}<inlineCode parentName="td">{`default`}</inlineCode>{` and `}<inlineCode parentName="td">{`monospace`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fontSizes`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains font-size tokens: keys are in `}<inlineCode parentName="td">{`px`}</inlineCode>{`, values are in `}<inlineCode parentName="td">{`rem`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fontWeights`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains font-weight tokens: `}<inlineCode parentName="td">{`regular`}</inlineCode>{`, `}<inlineCode parentName="td">{`medium`}</inlineCode>{`, and `}<inlineCode parentName="td">{`bold`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "web-example-3"
    }}>{`Web Example`}</h4>
    <p>{`Here's an example using `}<inlineCode parentName="p">{`fontFamily`}</inlineCode>{`, `}<inlineCode parentName="p">{`fontSize`}</inlineCode>{`, and `}<inlineCode parentName="p">{`fontWeight`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import {system} from '@workday/canvas-tokens-web';

const customTextStyles = {
  fontFamily: \`var(\${system.fontFamily.default})\`,
  fontSize: \`var(\${system.fontSize.body.small})\`,
  fontWeight: \`var(\${system.fontWeight.medium})\`,
};

const CustomText = () => (
  <p css={customTextStyles}>This is custom Roboto medium text using type properties.</p>
);
`}</code></pre>
    <h4 {...{
      "id": "quick-reference"
    }}>{`Quick Reference`}</h4>
    <p>{`Here's a quick reference guide for the values contained in each property:`}</p>
    <h5 {...{
      "id": "font-families"
    }}>{`Font Families`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`default`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"Roboto", "Helvetica Neue", "Helvetica", Arial, sans-serif`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`monospace`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"Roboto Mono", "Courier New", Courier, monospace`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <FontFamilyTokens mdxType="FontFamilyTokens" />
    <h5 {...{
      "id": "font-sizes"
    }}>{`Font Sizes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name (`}<inlineCode parentName="th">{`px`}</inlineCode>{`)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value (`}<inlineCode parentName="th">{`rem`}</inlineCode>{`)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`10`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'0.625rem'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`12`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'0.75rem'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`14`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'0.875rem'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`16`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'1rem'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`18`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'1.125rem'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`20`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'1.25rem'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`24`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'1.5rem'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`28`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'1.75rem'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`32`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'2rem'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`40`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'2.5rem'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`48`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'3rem'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`56`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'3.5rem'`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <FontSizeTokens mdxType="FontSizeTokens" />
    <h3 {...{
      "id": "creating-combinations"
    }}>{`Creating Combinations`}</h3>
    <p>{`As previously mentioned, in most cases all you'll need will be the `}<inlineCode parentName="p">{`levels`}</inlineCode>{` of the type hierarchy.
But these tokens can also be used to create style combinations when necessary. Below is a web
example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import {system} from '@workday/canvas-tokens-web';
import {createStyles} from '@workday/canvas-kit-styling';

const listItemBaseStyles = createStyles({
  padding: 0,
  ...system.type.subtext.large,
});

interface InverseListItemProps {
  isActive?: boolean;
}

const InverseListItem = (props: InverseListItemProps) => {
  const listItemActiveStyles = props.isActive
    ? {
        fontWeight: \`var(\${system.fontWeight.medium})\`,
        color: \`var(\${system.color.text.inverse})\`,
      }
    : {};

  return <li css={{...listItemBaseStyles, ...listItemActiveStyles}}>First List Item</li>;
};
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` We are using `}<inlineCode parentName="p">{`createStyles`}</inlineCode>{` which wraps our tokens in `}<inlineCode parentName="p">{`var(--\${tokenName})`}</inlineCode>{` and allows
you to spread the token styles onto the element.`}</p>
    </blockquote>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "ios-examples"
      }}>{`iOS Examples`}</h2>
      <p>{`Coming soon!`}</p>
      <h2 {...{
        "id": "android-examples"
      }}>{`Android Examples`}</h2>
      <p>{`Coming soon!`}</p>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      