
  import * as React from 'react';
import {system} from '@workday/canvas-tokens-web';
import {TokenGrid, formatJSVar} from './TokenGrid';

interface FontWeightToken {
  /** The name of the CSS variable */
  cssVar: string;
  /** The formatted name of the JS variable */
  jsVar: React.ReactNode;
  /** The actual string value of the token */
  value: string;
}

const fontWeightTokens: FontWeightToken[] = Object.entries(system.fontWeight).map(
  ([key, varName]) => {
    const value =
      typeof window !== 'undefined'
        ? getComputedStyle(document.documentElement).getPropertyValue(varName)
        : '';

    return {
      cssVar: varName,
      jsVar: formatJSVar(`system.fontWeight.${key}`),
      value: value,
    };
  }
);

export function FontWeightTokens() {
  return (
    <TokenGrid
      caption="font weight tokens"
      headings={['Sample', 'CSS Variable', 'JS Variable', 'Value']}
      rows={fontWeightTokens}
    >
      {token => (
        <>
          <TokenGrid.RowItem>
            <TokenGrid.Sample style={{fontWeight: token.value}}>Canvas</TokenGrid.Sample>
          </TokenGrid.RowItem>
          <TokenGrid.RowItem>
            <TokenGrid.MonospaceLabel>{token.cssVar}</TokenGrid.MonospaceLabel>
          </TokenGrid.RowItem>
          <TokenGrid.RowItem>
            <TokenGrid.MonospaceLabel>{token.jsVar}</TokenGrid.MonospaceLabel>
          </TokenGrid.RowItem>
          <TokenGrid.RowItem>{token.value}</TokenGrid.RowItem>
        </>
      )}
    </TokenGrid>
  );
}

  FontWeightTokens.__RAW__ = "import * as React from 'react';\nimport {system} from '@workday/canvas-tokens-web';\nimport {TokenGrid, formatJSVar} from './TokenGrid';\n\ninterface FontWeightToken {\n  /** The name of the CSS variable */\n  cssVar: string;\n  /** The formatted name of the JS variable */\n  jsVar: React.ReactNode;\n  /** The actual string value of the token */\n  value: string;\n}\n\nconst fontWeightTokens: FontWeightToken[] = Object.entries(system.fontWeight).map(\n  ([key, varName]) => {\n    const value =\n      typeof window !== 'undefined'\n        ? getComputedStyle(document.documentElement).getPropertyValue(varName)\n        : '';\n\n    return {\n      cssVar: varName,\n      jsVar: formatJSVar(`system.fontWeight.${key}`),\n      value: value,\n    };\n  }\n);\n\nexport function FontWeightTokens() {\n  return (\n    <TokenGrid\n      caption=\"font weight tokens\"\n      headings={['Sample', 'CSS Variable', 'JS Variable', 'Value']}\n      rows={fontWeightTokens}\n    >\n      {token => (\n        <>\n          <TokenGrid.RowItem>\n            <TokenGrid.Sample style={{fontWeight: token.value}}>Canvas</TokenGrid.Sample>\n          </TokenGrid.RowItem>\n          <TokenGrid.RowItem>\n            <TokenGrid.MonospaceLabel>{token.cssVar}</TokenGrid.MonospaceLabel>\n          </TokenGrid.RowItem>\n          <TokenGrid.RowItem>\n            <TokenGrid.MonospaceLabel>{token.jsVar}</TokenGrid.MonospaceLabel>\n          </TokenGrid.RowItem>\n          <TokenGrid.RowItem>{token.value}</TokenGrid.RowItem>\n        </>\n      )}\n    </TokenGrid>\n  );\n}\n";