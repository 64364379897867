
  import * as React from 'react';
import {system} from '@workday/canvas-tokens-web';
import {TokenGrid, formatJSVar} from './TokenGrid';

interface FontFamilyToken {
  /** The name of the CSS variable */
  cssVar: string;
  /** The formatted name of the JS variable */
  jsVar: React.ReactNode;
  /** The actual string value of the token */
  value: string;
}

const fontFamilyTokens: FontFamilyToken[] = Object.entries(system.fontFamily).map(
  ([familyName, varName]) => {
    const value =
      typeof window !== 'undefined'
        ? getComputedStyle(document.documentElement).getPropertyValue(varName)
        : '';

    return {
      cssVar: varName,
      jsVar: formatJSVar(`system.fontFamily.${familyName}`),
      value: value.replace(/"/g, ''),
    };
  }
);

export function FontFamilyTokens() {
  return (
    <TokenGrid
      caption="font family tokens"
      headings={['Sample', 'CSS Variable', 'JS Variable', 'Value']}
      rows={fontFamilyTokens}
    >
      {token => (
        <>
          <TokenGrid.RowItem>
            <span style={{fontFamily: token.value}}>Canvas</span>
          </TokenGrid.RowItem>
          <TokenGrid.RowItem>
            <TokenGrid.MonospaceLabel>{token.cssVar}</TokenGrid.MonospaceLabel>
          </TokenGrid.RowItem>
          <TokenGrid.RowItem>
            <TokenGrid.MonospaceLabel>{token.jsVar}</TokenGrid.MonospaceLabel>
          </TokenGrid.RowItem>
          <TokenGrid.RowItem>{token.value}</TokenGrid.RowItem>
        </>
      )}
    </TokenGrid>
  );
}

  FontFamilyTokens.__RAW__ = "import * as React from 'react';\nimport {system} from '@workday/canvas-tokens-web';\nimport {TokenGrid, formatJSVar} from './TokenGrid';\n\ninterface FontFamilyToken {\n  /** The name of the CSS variable */\n  cssVar: string;\n  /** The formatted name of the JS variable */\n  jsVar: React.ReactNode;\n  /** The actual string value of the token */\n  value: string;\n}\n\nconst fontFamilyTokens: FontFamilyToken[] = Object.entries(system.fontFamily).map(\n  ([familyName, varName]) => {\n    const value =\n      typeof window !== 'undefined'\n        ? getComputedStyle(document.documentElement).getPropertyValue(varName)\n        : '';\n\n    return {\n      cssVar: varName,\n      jsVar: formatJSVar(`system.fontFamily.${familyName}`),\n      value: value.replace(/\"/g, ''),\n    };\n  }\n);\n\nexport function FontFamilyTokens() {\n  return (\n    <TokenGrid\n      caption=\"font family tokens\"\n      headings={['Sample', 'CSS Variable', 'JS Variable', 'Value']}\n      rows={fontFamilyTokens}\n    >\n      {token => (\n        <>\n          <TokenGrid.RowItem>\n            <span style={{fontFamily: token.value}}>Canvas</span>\n          </TokenGrid.RowItem>\n          <TokenGrid.RowItem>\n            <TokenGrid.MonospaceLabel>{token.cssVar}</TokenGrid.MonospaceLabel>\n          </TokenGrid.RowItem>\n          <TokenGrid.RowItem>\n            <TokenGrid.MonospaceLabel>{token.jsVar}</TokenGrid.MonospaceLabel>\n          </TokenGrid.RowItem>\n          <TokenGrid.RowItem>{token.value}</TokenGrid.RowItem>\n        </>\n      )}\n    </TokenGrid>\n  );\n}\n";